/* TABLE STYLING */
table {
  @apply w-full;
}
thead {
  @apply bg-rules;
}
th {
  @apply text-left;
}
.sticky-table-header {
  @apply bg-rules sticky text-slate-gray;
}
tbody tr {
  @apply odd:bg-white even:bg-light-gray  border-rules;
}
table td {
  @apply p-3 border-r border-rules sm:last:border-r-0;
}

.down-arrow {
  transform: scaleY(1);
}
.up-arrow {
  transform: scaleY(-1);
}

/* MIN TABLE STYLES */
.min-thead {
  @apply hidden;
}
.min-tr {
  @apply border-rules border-l border-b-4 border-r border-t bg-white block mb-4 !important;
}

.min-td {
  @apply border-rules border-b border-r-0 flex justify-between text-right text-sm  !important;
}

.min-td-before {
  content: attr(data-label);
  @apply float-left font-bold uppercase;
}

.min-skeleton {
  @apply float-none;
}

/* APPLY MIN TABLE STYLES TO MANUALLY TAGGED MIN TABLES */
.minimize-table thead {
  @apply min-thead;
}
.minimize-table tr {
  @apply min-tr;
}

.minimize-table td {
  @apply min-td;
}

.minimize-table td::before {
  @apply min-td-before;
}

.minimize-table .skeleton {
  @apply min-skeleton;
}

/* APPLY MIN TABLE STYLE TO ALL TABLES*/
@media screen and (max-width: 639px) {
  table thead {
    @apply min-thead;
  }
  table tr {
    @apply min-tr;
  }

  table td {
    @apply min-td;
  }

  table td::before {
    @apply min-td-before;
  }

  table .skeleton {
    @apply min-skeleton;
  }
}

.table-highlight-first-row tbody tr:first-child {
  @apply bg-light-blue font-bold border-2 border-primary !important;
}
