@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("table.css");
@import url("nav.css");
@import url("typography.css");
@import url("modal.css");

* {
  font-family: "Open Sans", sans-serif;
}

:focus {
  outline: none;
}

html {
  overflow-y: scroll;
}

body {
  width: 100%;
}

/* PAYER LANDSCAPE */
.sticky-filters {
  @apply md:sticky z-10 bg-white pt-1;
  top: 53px;
}
/* BREADCRUMBS */
.breadcrumb-container li::before {
  content: "›";
  position: relative;
  left: -7px;
  font-size: 20px;
}
.breadcrumb-container li:first-child::before {
  content: "";
}

.breadcrumb-container li {
  @apply flex items-center;
}

/* INPUTS */
input,
select {
  background-color: unset;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.input-container {
  @apply text-lg sm:text-base bg-light-gray px-2 border-b border-rules ring-primary focus-within:ring-2;
  line-height: 1rem !important;
}

.range-container input::placeholder {
  font-size: 14px;
}
@media screen and (min-width: 640px) {
  .range-container {
    max-width: 250px;
  }
}

.select-arrow {
  @apply float-right -mt-1 mr-2;
  margin-bottom: -40px;
  margin-top: 10px;
}

/* BUTTONS */
.btn {
  @apply flex items-center space-x-2 px-3 py-2 justify-center focus:ring;
}

.btn-md {
  @apply py-3;
}

.btn-lg {
  @apply py-4;
}

.btn[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn-primary {
  @apply bg-primary text-white  hover:bg-primary-hover;
}

.btn-secondary {
  @apply bg-secondary text-white  hover:bg-secondary-hover;
}

.btn-rules {
  @apply bg-rules text-black  hover:bg-steel;
}

.btn-slate-gray {
  @apply bg-slate-gray text-white  hover:bg-black;
}
.btn-error {
  @apply bg-error text-white  hover:bg-error-hover;
}

/* TOOLTIPS */
.MuiTooltip-tooltip {
  @apply rounded bg-slate-gray text-white p-4 mt-2 shadow !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
}
.MuiTooltip-arrow {
  @apply text-slate-gray !important;
}
.tooltip-tail {
  @apply bg-slate-gray;
  height: 10px;
  width: 10px;
  display: inline-block;
  position: absolute;
  top: 3px;
  transform: rotate(45deg);
}

/* PDF PREVIEW*/
.pdf-preview-container {
  @apply flex-grow flex flex-col items-center lg:pt-10 bg-light-gray shadow-inner overflow-x-hidden rounded-l;
}

.react-pdf__Page__canvas {
  @apply bg-steel;
  max-width: 100%;
  height: auto !important;
}

@media screen and (min-width: 1024px) {
  .pdf-preview-container {
    @apply overflow-y-auto;
    height: 93vh;
  }
}
@media screen and (max-width: 1023px) {
  .pdf-preview-container {
    overflow: hidden;
  }
}
