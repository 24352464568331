/* MODAL */
.modal-backdrop {
  @apply fixed bg-black bg-opacity-40 h-screen w-full sm:flex justify-center z-40 max-h-screen overflow-auto;
  backdrop-filter: blur(3px);
}

.modal-card {
  @apply bg-white rounded p-4 sm:p-8 clear-both mx-4 relative shadow-2xl my-8;
}

.modal-button {
  @apply float-right relative mx-4 z-40;
  top: 45px;
  right: 15px;
}

@media screen and (max-width: 639px) {
  .modal-card {
    top: 0;
  }

  .modal-button {
    top: 45px;
  }
}
