/* TYPOGRAPHY */
.header-1 {
  font-weight: 600;
  font-size: 48px;
  letter-spacing: 0.16px;
}

/* PAGE HEADERS */
.header-2 {
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
}

.header-3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
}

.header-4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 18px;
  letter-spacing: 0.16px;
}

.header-5-semi {
  font-weight: 600;
  font-size: 18px;
  line-height: 40px;
}

.header-5-regular {
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
}

.header-6-semi {
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.16px;
}

.header-6-regular {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.16px;
}

.body-1 {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16px;
}

.body-2 {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16px;
}

.body-3 {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.32px;
}
