/* NAV */
.nav {
  @apply pt-4 pb-2 block lg:text-center lg:border-b-6 border-slate-gray px-3 font-extrabold uppercase lg:normal-case lg:font-normal border-b;
}

.nav:focus {
  @apply ring;
}

.active-nav {
  @apply lg:bg-light-blue lg:border-primary font-bold;
}

.sub-nav li a {
  @apply py-3 px-3 text-left;
}

.sub-nav {
  @apply lg:hidden lg:bg-light-gray lg:text-slate-gray lg:shadow-lg;
}

.nav-container:hover .sub-nav {
  @apply lg:absolute;
  display: block;
  z-index: 10;
}

.sub-nav li a:hover {
  @apply bg-light-blue;
}

.queue-notification {
  @apply relative  px-2 flex items-center justify-center text-sm bg-error rounded-full -mt-8 -mr-4 font-bold;
  margin-left: 17px;
  height: 22px;
}

.print-indicator-container {
  @apply pt-4 px-4 h-12;
  height: 54px;
}

.print-sub-nav {
  @apply hidden text-slate-gray;
}

.nav-container:hover .print-sub-nav {
  @apply absolute;
  display: block;
  z-index: 10;
}
